import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `transparent`,
      marginBottom: `1.45rem`,
      // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", /* Shadow */
      alignItems: "center", // Aligns items vertically
    }}
  >
    <div style={{ maxWidth: '200px', margin: '0 auto' }}>
      <Image filename="LogoLong.png" maxWidth={300} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
